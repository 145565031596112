<template>
  <div class="app-container">
    <RebateHeader accountType="YEARLY_VIRTUAL_CREDIT" v-if="accountType === 'rebate-list'" />
    <el-form
      :inline="true"
      :model="formInline"
      class="demo-form-inline"
      v-if="accountType !== 'rebate-list'"
    >
      <el-form-item label="经营主体：">
        <el-select clearable v-model="formInline.data.ownCompanyId" placeholder="请选择经营主体">
          <el-option label="全部" value=""></el-option>
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="(item, index) in ownCompanyInfoList"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="出入账类型：">
        <el-select
          clearable
          v-model="formInline.data.incomeOrOutType"
          placeholder="请选择出入账类型"
        >
          <el-option label="全部" value=""></el-option>
          <el-option label="入账" value="INCOME"></el-option>
          <el-option label="出账" value="OUT"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="onSearch">查询</el-button>
        <el-button @click="onReset" size="small">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="list"
      element-loading-text="加载中"
      fit
      highlight-current-row
      ref="multipleTable"
      v-loading.body="listLoading"
    >
      <el-table-column align="center" label="变动类型" prop="channelName"></el-table-column>
      <el-table-column align="center" label="出入账类型">
        <template slot-scope="{ row }">
          <span>
            {{ row.changeAmount > 0 ? '入账' : '出账' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        v-if="accountType === 'rebate-list'"
        align="center"
        label="账户名称"
        prop="distributorPartyRelationName"
      >
        <template slot-scope="{ row }">
          <span v-if="row.accountType === 'VIRTUAL_CREDIT'"> 水羊直供平台返利 </span>
          <span v-if="row.accountType === 'YEARLY_VIRTUAL_CREDIT'">
            {{ row.yearlyContractInfoName }}-{{ row.yearlyOwnCompanyName }}-{{
              row.subtypeValueName
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="变动金额">
        <template slot-scope="{ row }">
          <span>
            {{ row.changeAmount > 0 ? '+' + row.changeAmount : row.changeAmount }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="经营主体" v-if="accountType !== 'rebate-list'">
        <template slot-scope="{ row }">
          <span>
            {{ row.ownCompanyName || '-' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="变动原因" prop="changeNotes"></el-table-column>
      <el-table-column align="center" label="变动时间">
        <template slot-scope="scope">{{ scope.row.createDate | parseDefaultTime() }}</template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        :current-page="formInline.pageNo"
        :disabled="listLoading"
        :page-size="formInline.pageSize"
        :page-sizes="[10, 20, 30, 40]"
        :total="total"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        background
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { listAccountDetailPage, listOwnCompanyInfo } from '@/api/shop';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';
import RebateHeader from './RebateHeader';
export default {
  name: '',
  components: { RebateHeader },
  data() {
    return {
      total: 0,
      list: [],
      listLoading: false,
      data: {},
      ownCompanyInfoList: [],
      title: '',
      formInline: {
        data: {
          ownCompanyId: '',
          incomeOrOutType: '',
        },
        pageNo: 1,
        pageSize: 10,
      },
    };
  },
  props: {
    accountType: String,
  },
  mounted() {
    const { ownCompanyId } = this.$route.query;
    this.formInline.data.ownCompanyId = ownCompanyId || '';
    if (this.accountType === 'rebate-list') {
      // 返利
      this.type = 'VIRTUAL_CREDIT';
    } else if (this.accountType === 'cash-deposit-list') {
      // 保证金
      this.type = 'DEPOSIT';
      this.fetchOwnCompanyInfo();
    } else if (this.accountType === 'account-balance-list') {
      // 账户余额
      this.type = 'BALANCE';
      this.fetchOwnCompanyInfo();
    }
    this.onSearch();
  },
  methods: {
    onReset() {
      this.formInline = cloneDeep({
        data: {
          ownCompanyId: '',
          incomeOrOutType: '',
        },
        pageNo: 1,
        pageSize: 10,
      });
      this.onSearch();
    },
    onSearch() {
      this.fetchData();
    },
    fetchData() {
      this.listLoading = true;
      const listQuery = pick(this.formInline, ['pageNo', 'pageSize', 'data']);
      if (this.accountType === 'rebate-list') {
        listQuery.data.accountTypes = ['YEARLY_VIRTUAL_CREDIT'];
      } else {
        listQuery.data.accountType = this.type;
      }
      listAccountDetailPage(listQuery)
        .then(response => {
          const { list = [], total = 0 } = response.data || {};
          this.list = list;
          this.total = total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    // 获取经营主体
    fetchOwnCompanyInfo() {
      listOwnCompanyInfo().then(response => {
        const data = response.data || {};
        this.ownCompanyInfoList = data.map(item => {
          return {
            value: item.ownCompanyId,
            label: item.ownCompanyName,
          };
        });
      });
    },
    handleSizeChange(val) {
      this.formInline.pageSize = val;
      this.formInline.pageNo = 1;
      this.fetchData();
    },
    handleCurrentChange(val) {
      this.formInline.pageNo = val;
      this.fetchData();
    },
    filtration(channel) {
      const addset = new Set(['SYS_ADD', 'RECHARGE_ADD', 'PURCHASE_REFUND_ADD']);
      if (addset.has(channel)) {
        return '+';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>