<template>
  <div class="rebate-header">
    <div class="header-wrap">
      <div class="item">
        <span class="key">整体可用返利余额</span>
        <span class="value">{{ formatAmount(totalAmount) }}</span>
      </div>
    </div>
    <div v-if="commonAccounts || yearAccounts.length" class="content-wrap">
      <div class="item-wrap">
        <div class="item" v-for="(item, index) in commonAccounts" :key="index">
          <span>{{item.subtypeValueName}}：</span>
          <span class="item-amount">￥{{item.amount}}</span>
        </div>
        <div class="item" v-for="(item, index) in yearAccounts" :key="index">
          <span>{{`${item.yearlyContractInfoName}-${item.yearlyOwnCompanyName}-${item.subtypeValueName}：`}}</span>
          <span class="item-amount">￥{{item.amount}}</span>
        </div>
      </div>
    </div>
    <div class="tip-wrap" v-if="accountType === 'YEARLY_VIRTUAL_CREDIT'">
      <div>
        1、自2024年9月21日起,年框返利将根据甲乙双方合作主体以及品牌拆分返利余额,不允许跨合作主体以及品牌使用。
      </div>
      <div>
        2、水羊直供获取的平台通用返利：2022年5月28日之前发放的返点，将于2022年11月27日失效；2022年5月28日之后发放的返点，每一笔有效期为180个自然日。
      </div>
    </div>
    <div class="tip-wrap" v-if="accountType === 'VIRTUAL_CREDIT'">
      <div>
        1、自2024年12月27日起，通过水羊直供下单获得的平台返利将会拆分品牌使用，即通过下单指定品牌获得的返利，后续只能用于对应品牌；
      </div>
    </div>
  </div>
</template>

<script>
import { merchantCustomerAccountListMyAccounts } from '@/api/shop';
export default {
  data() {
    return {
      totalAmount: '',
      loseAmount: '',
      soonLoseAmount: '',
      commonAccounts: '',
      yearAccounts: [],
    };
  },
  props: {
    accountType: {
      type: String,
      default: 'VIRTUAL_CREDIT',
    },
  },
  mounted() {
    this.getCommonAccounts();
    this.getYearAccounts();
  },
  methods: {
    formatAmount(value) {
      if (value || value === '0') {
        return `￥${value.toFixed(2)}`;
      }
      return '--';
    },
    getCommonAccounts() {
    this.accountType === 'VIRTUAL_CREDIT' && merchantCustomerAccountListMyAccounts('VIRTUAL_CREDIT').then(response => {
        const data = response.data || [];
        if (data.length) {
          this.commonAccounts = data;
        }
        this.totalAmount = data.reduce((accumulator, currentObject) => {
          return accumulator + currentObject.amount;
        }, 0);
      });
    },
    getYearAccounts() {
      this.accountType === 'YEARLY_VIRTUAL_CREDIT' && merchantCustomerAccountListMyAccounts('YEARLY_VIRTUAL_CREDIT').then(response => {
        const data = response.data || [];
        this.yearAccounts = data;
        this.totalAmount = data.reduce((accumulator, currentObject) => {
          return accumulator + currentObject.amount;
        }, 0);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.rebate-header {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 23px 16px 20px 16px;
  margin-bottom: 15px;
  .header-wrap {
    display: flex;
    margin-bottom: 10px;
    .item {
      display: flex;
      flex-direction: column;
      min-width: 112px;
      gap: 8px;
      .key {
        font-size: 14px;
        color: #999999;
      }
      .value {
        font-weight: bold;
        color: #333333;
        font-size: 18px;
      }
    }
    .m-gap {
      margin: 0 27px 0 42px;
    }
  }
  .content-wrap {
    background-color: #f5f5f5;
    padding: 12px 14px;
    margin-bottom: 10px;
    position: relative;
    .commmon-wrap {
      display: flex;
      flex-wrap: wrap;
    }
    .item-wrap {
      max-height: 100px;
      overflow: auto;
    }
    .m-magin {
      margin-right: 8px;
    }
    .item {
      font-size: 12px;
      color: #666666;
      height: 20px;
      line-height: 20px;
      .item-amount {
        font-weight: bold;
        color: #333333;
      }
    }
  }
  .content-wrap::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 30px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 10px solid #f5f5f5;
  }
  .tip-wrap {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    color: #999999;
    gap: 4px;
  }
}
</style>